

export function PaymentTransactions() {
    return (
        <section className="bg-gray-light pt-5 pb-5">
            <div className="container border-bottom">
                <div className="row mb-5">
                    <div id="paymenttransaction" className="anchor"/>
                    <div className="d-flex flex-column justify-content-start ">
                        <div>
                            <span className="bg-base-color d-inline-block sep-line"></span>
                            <span
                                className="text-align-left d-block font-alt letter-spacing-2 text-uppercase title-medium">Payment Transaction Management</span>

                        </div>
                        <div className={"mt-1 text-blue"}>Your customers want to send their payment transactions
                            directly to
                            you, not log into online banking? Not a problem!
                        </div>

                        <div className="mt-2">
                            <p className="text-left  text-medium">
                                No matter how your customers generate their payments, whether they use an ERP,
                                accounting
                                application or other system, no matter what format or channel they use, our FI-Streams
                                Payments
                                product will handle it. Setup is quick and easy, get them up and running with you in no
                                time! </p>
                        </div>

                        <p className="text-left text-blue">
                            FI-Streams Payments provides
                            Data Streaming capability with end-to-end data delivery of payments through your/your
                            customers’
                            digital channel of choice.
                        </p>

                        <div className="d-flex justify-content-center ">
                            <img src="assets/images/payment_management.png" width="850" alt=""
                                 className="logo-text-light mt-2 border-blue"/>
                        </div>


                    </div>

                </div>
            </div>
        </section>
                );
                }
