import React from "react";

export function BlogTwo() {

    return (
        <div>
            <section id="blog" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-8 pt-3 text-center">

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Blog
                            </h2>

                        </div>

                    </div>

                </div>

            </section>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>4/5/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Prolific Banking Inc. Signs Brookline Bank to its Bank Innovation Partnership Program
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=brookline_innovation_partner" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>

                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>3/19/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Leveraging Cloud Technology for Business Part 2: Business Benefits of Cloud and SaaS (Software-as-a-Service) Applications
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=leverage_cloud_for_biz_p2" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>

                </div>
            </div>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>3/9/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                The Business Case for Commercial Banking Enrollment Automation
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=business_case" className="text-black-light text-underline">Read More</a>
                        </p>


                    </div>
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>2/24/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Commercial Banking Digital Engagement: A Necessary Foundation for Open Banking
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=digital_engagement_foundation" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>
                </div>
            </div>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>2/17/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Leveraging Cloud Technology for Business — Part 1: Digital Integrations and Vertical Solutions
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=leverage_cloud_for_biz_p1" className="text-black-light text-underline">Read More</a>
                        </p>


                    </div>
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>2/8/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                The Dynamics of a Successful Fintech Partnership
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=dynamics_successful_fintech_partner" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>
                </div>
            </div>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>2/3/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Identity Access Management (IAM): The Key to Secure Client Remote Access
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=iam_the_key" className="text-black-light text-underline">Read More</a>
                        </p>


                    </div>
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>1/12/21</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                We are proud to introduce our newest digital innovation: On-Ramp
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=intro_onramp" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>
                </div>
            </div>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>12/20/20</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Year-end message from Scott Graf, founder and president, Prolific Banking
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=year_end_20" className="text-black-light text-underline">Read More</a>
                        </p>


                    </div>
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>11/17/20</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                How to prevent sinking in a vast sea change
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=vast_sea_change" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>
                </div>
            </div>

            <div className="container text-align-lleft">
                <div className="row">
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>10/30/20</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                Start the Transformation, Open the Bank to Open Banking
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=start_transformation" className="text-black-light text-underline">Read More</a>
                        </p>


                    </div>
                    <div className="col-lg-6 pt-3">
                        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                        <div>
                            <p>10/21/20</p>

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                                The Reality Behind the Mystique of Open Banking
                            </h2>
                        </div>

                        <p className="d-block font-alt mb-3 text-medium">
                            <a href="/blogc?n=reality_open_banking" className="text-black-light text-underline">Read More</a>
                        </p>

                    </div>
                </div>
            </div>

            <div className="container text-align-right">
                <div className="row">
                    <div className="col-lg-12 pt-3">
                        {/*<span className="bg-base-color d-inline-block sep-line-medium-thick"/>*/}

                        <p className="d-block font-alt mt-5 mb-3 text-large">
                            <a href="/blogg" className="text-blue-2 text-underline">Newer Posts</a>
                        </p>

                    </div>
                </div>
            </div>


            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>
        )

}