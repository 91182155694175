import {Link} from "react-router-dom";
import React from "react";

export function CustomerAccess() {
    return(
        <section id="customeraccess" className="bg-cover bg-gray bg-overlay-black-6">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 text-white">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-blue-2 text-uppercase title-xs-small title-extra-large-1">Our Relationships matter</h3>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Customer and Partner Access</p>
                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                        <div className="mt-4 text-extra-large text-white">
                            <p><a href="/#contact" className="text-blue-2">Register</a> or Login to speak with our team and access more information.</p>
                            <div className="mt-4"><span>More Information:</span> <span className="text-blue-2">info@prolificbanking.com</span></div>
                            <div className="mt-4"><span>Speak With Us: 1-833-PROLIFC (776-5432)</span></div>
                        </div>

                    </div>


                    <div className="col-lg-6 mt-5 mt-lg-0">

                        <p className="font-alt mb-0 mt-3 mb-5 text-xs-large text-uppercase text-white title-medium">Login for access</p>

                        <form action="contact/send_mail.php" method="post" id="form-contact">
                            <div className="form-group">
                                {/*<span*/}
                                {/*    className="d-block font-alt letter-spacing-1 text-medium text-uppercase text-white">User Name</span>*/}
                                <input type="text" name="username" className="font-alt form-control mt-2 required" id="username"
                                       placeholder="User Name"/>
                            </div>

                            <div className="form-group">
                                {/*<span className="d-block font-alt letter-spacing-1 text-medium text-uppercase text-white">Password</span>*/}
                                <input type="password" name="password" className="font-alt form-control mt-2 required email"
                                       id="password" placeholder="Password"/>
                            </div>

                            <div className="mt-5">
                                <span className="d-block font-alt letter-spacing-1 text-small text-uppercase text-white">*Please complete all fields</span>
                                <button type="submit" className="btn btn-shadow btn-base-color mt-4 text-uppercase"
                                        id="btn-form-contact">Sign In
                                </button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>

        </section>
    )
}