import {FirstSection} from "./FirstSection";
import {About} from "./About";
import {Products} from "./Products";
import {Team} from "./Team";
import {Careers} from "./Careers";
import {WhyUs} from "./WhyUs";
import {Contact} from "./Contact";
import {Footer} from "./Footer";
import {Link} from "react-router-dom";

export function Compliance() {

    return (
        <div>

            <section id="compliance" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-8 pt-3 text-center">



                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Security and Compliance
                            </h2>

                        </div>

                    </div>

                </div>

            </section>

            <section className="mt-n5">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-8 pt-3 text-center">

                            <span className="bg-base-color d-inline-block sep-line-medium-thick mb-5"></span>

                            <p className="d-block font-alt letter-spacing-2 mb-3 text-large">
                            The reporting and investigation of compliance and/or security incidents play a key part in creating a culture of honest and ethical behavior and conduct. Additionally, management of compliance and/or security issues are essential tools for Prolific Banking products and services.  Working together with our partners we enable an inclusive working environment by taking appropriate actions to mitigate threats and risks.
                            </p>

                            <p className="d-block font-alt letter-spacing-2 mb-3 text-large">
                            Anyone who becomes aware of a compliance, privacy, fraud or security incident, whether it has occurred or is about to occur, should report it to Prolific Banking. There are a number of ways to report through the options provided below. If you wish to remain anonymous, you may do so by calling the Support Hotline and request to remain anonymous.
                            </p>

                        </div>

                    </div>

                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-blue-2 text-uppercase title-large">
                                To Report an Issue of Compliance or Security
                            </h2>

                            <div className="d-block font-alt letter-spacing-2 mt-5 mb-3 text-large">
                                Call the Support Hotline: 1-866-776-5432
                            </div>

                            <div className="d-block font-alt letter-spacing-2 mt-5 mb-3 text-large">
                                Email: <a href="mailto:compliance@prolificbanking.com">compliance@prolificbanking.com</a>
                            </div>

                        </div>

                    </div>



                </div>
            </section>

        </div>
        )

}