
export function Team() {
    return(
        <section id="team" className="bg-white">
            <div className="container">
                <div className="row justify-content-center pb-5">
                    <div className="col-lg-9 pb-lg-4 text-center">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Meet
                            The Team</h3>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">We are here to help
                            you with anything you need</p>
                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"></span>
                    </div>

                </div>


                <div className="row">

                    <div className="col-md-6 col-lg-4">
                        <figure className="team-block rounded">
                            <img src="assets/images/scott.jpg" alt="" className="img-fluid ease rounded"/>

                                <figcaption className="ease h-100 left-0 position-absolute rounded top-0 w-100">
                                    <div className="d-table h-100 position-relative px-4 w-100">
                                        <div className="d-table-cell align-middle text-center">
                                            <span
                                                className="d-block font-alt letter-spacing-2 title-medium text-uppercase text-white">Scott Graf</span>
                                            <span className="bg-base-color d-inline-block mt-2 sep-line-thick"></span>
                                            <span
                                                className="d-block font-alt letter-spacing-2 mt-3 text-medium text-uppercase text-white">President</span>
                                        </div>

                                    </div>

                                </figcaption>
                        </figure>
                    </div>

                    {/*<div className="col-md-6 col-lg-3">*/}
                    {/*    <figure className="team-block rounded">*/}
                    {/*        <img src="assets/images/joe.jpg" width="768" height="1152" alt="" className="img-fluid ease rounded"/>*/}

                    {/*        <figcaption className="ease h-100 left-0 position-absolute rounded top-0 w-100">*/}
                    {/*            <div className="d-table h-100 position-relative px-4 w-100">*/}
                    {/*                <div className="d-table-cell align-middle text-center">*/}
                    {/*                            <span*/}
                    {/*                                className="d-block font-alt letter-spacing-2 title-medium text-uppercase text-white">Joe Spatarella</span>*/}
                    {/*                    <span className="bg-base-color d-inline-block mt-2 sep-line-thick"></span>*/}
                    {/*                    <span*/}
                    {/*                        className="d-block font-alt letter-spacing-2 mt-3 text-medium text-uppercase text-white">Chief Client Success Officer</span>*/}
                    {/*                </div>*/}

                    {/*            </div>*/}

                    {/*        </figcaption>*/}
                    {/*    </figure>*/}
                    {/*</div>*/}

                    <div className="col-md-6 col-lg-4">
                        <figure className="team-block rounded">
                            <img src="assets/images/jennifer.jpg" alt="" className="img-fluid ease rounded"/>

                                <figcaption className="ease h-100 left-0 position-absolute rounded top-0 w-100">
                                    <div className="d-table h-100 position-relative px-4 w-100">
                                        <div className="d-table-cell align-middle text-center">
                                            <span
                                                className="d-block font-alt letter-spacing-2 title-medium text-uppercase text-white">Jennifer Schulte</span>
                                            <span className="bg-base-color d-inline-block mt-2 sep-line-thick"></span>
                                            <span
                                                className="d-block font-alt letter-spacing-2 mt-3 text-medium text-uppercase text-white">VP Technology</span>
                                        </div>

                                    </div>

                                </figcaption>
                        </figure>
                    </div>

                    <div className="col-md-6 col-lg-4">
                        <figure className="team-block rounded">
                            <img src="assets/images/skyanna.jpg" alt="" className="img-fluid ease rounded"/>

                                <figcaption className="ease h-100 left-0 position-absolute rounded top-0 w-100">
                                    <div className="d-table h-100 position-relative px-4 w-100">
                                        <div className="d-table-cell align-middle text-center">
                                            <span
                                                className="d-block font-alt letter-spacing-2 title-medium text-uppercase text-white">Skyanna Page</span>
                                            <span className="bg-base-color d-inline-block mt-2 sep-line-thick"></span>
                                            <span
                                                className="d-block font-alt letter-spacing-2 mt-3 text-medium text-uppercase text-white">Director Product</span>
                                        </div>

                                    </div>

                                </figcaption>
                        </figure>
                    </div>

                </div>

            </div>

        </section>
    )
}
