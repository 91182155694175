import React from "react";
import _ from "lodash";

var posts = [
    {"date" : new Date("9/20/22"), "title" : "Commercial Banking Digital Innovation: How Soon is Now?", "link" : "how_soon_is_now"},
    {"date" : new Date("5/27/22"), "title" : "Transformation Conversations – A New Generation of Information & Transaction Delivery", "link" : "tc_transaction_delivery"},
    {"date" : new Date("5/19/22"), "title" : "Brookline Bancorp Moves Prolific Banking Inc. On-Ramp Commercial Enrollment Solution into Production", "link" : "brookline_live"},
    {"date" : new Date("4/28/22"), "title" : "Prolific Banking Inc. Partners with ACI Worldwide to Drive Digital Banking", "link" : "aci_partner"},
    {"date" : new Date("4/8/22"), "title" : "Transformation Conversations – APIs: They are not Lego Blocks", "link" : "lego_blocks"},
    {"date" : new Date("3/25/22"), "title" : "Transformation Conversations – Growing Pains: Leveraging Automation to Create Scale", "link" : "leverage_automation"},
    {"date" : new Date("12/29/21"), "title" : "Forward Momentum", "link" : "forward_momentum"},
    {"date" : new Date("10/8/21"), "title" : "Transformation Conversations – Payment Innovations, Part 3: Payment Onboarding for Business & Commercial Clients", "link" : "payment_innovations_3"},
    {"date" : new Date("9/28/21"), "title" : "Brookline Bank Signs Multi-Year Contract with ProlificBanking Inc. for Commercial Onboarding Solution", "link" : "brookline_multiyear"},
    {"date" : new Date("9/17/21"), "title" : "Transformation Conversations – Payment Innovations, Part 2: Achieving Strategic Differentiation", "link" : "payment_innovations_2"},
    {"date" : new Date("9/9/21"), "title" : "Transformation Conversations – Payment Innovations, Part 1: Rationalizing the Expansion of Payment Options", "link" : "payment_innovations_1"},
    {"date" : new Date("8/13/21"), "title" : "Transformation Conversations – Leveraging Technology: A Closer Look at Robotic Process Automation (RPA) Part 2", "link" : "leverage_technology_2"},
    {"date" : new Date("8/6/21"), "title" : "Transformation Conversations – Leveraging Technology: A Closer Look at Robotic Process Automation (RPA)", "link" : "leverage_technology_1"},
    {"date" : new Date("7/16/21"), "title" : "Transformation Conversations – Cloud Technology and the Art of Integration", "link" : "tc_cloud_tech_art_integration"},
    {"date" : new Date("6/18/21"), "title" : "Transformation Conversations - A Closer Look at Digital Engagement for Business and Commercial Customers", "link" : "tc_closer_digital_engage_business"},
    {"date" : new Date("6/4/21"), "title" : "Transformation Conversations - Bank Customer Information Management for Business and Commercial Clients", "link" : "tc_bank_cust_info_management"},
    {"date" : new Date("5/27/21"), "title" : "Transformation Conversations - Digital Banking & Cyber Security: Will it Stand up to New Threats?", "link" : "tc_digital_banking_cyber_secure"},
    {"date" : new Date("5/21/21"), "title" : "Transformation Conversations - How do Fintechs Influence Bank Technology Projects?", "link" : "tc_fintechs_influence"},
    {"date" : new Date("5/14/21"), "title" : "Transformation Conversations - Is Cloud Computing Just Outsourcing with a New Label?", "link" : "tc_cloud_computing"},
    {"date" : new Date("5/7/21"), "title" : "Transformation Conversations - How Digital Transformation Benefits Banks and Their Business & Commercial Customers", "link" : "tc_digital_transformation_benefits"},
    {"date" : new Date("4/30/21"), "title" : "Transformation Conversations - What it Means to be Prolific", "link" : "tc_means_to_be_prolific"},
    {"date" : new Date("4/15/21"), "title" : "Welcome to the Future: Operating in a Rapidly Changing Industry", "link" : "operating_rapid_change"},
    {"date" : new Date("4/6/21"), "title" : "Video Blog: Discovering the Value of Cloud Computing for Business Customers", "link" : "vb_value_of_cloud_computing"},
]

const blogRow = (post_pair, idx) => {

    console.log(post_pair);
    return (
        <div className="container text-align-lleft" key={"r"+idx}>
            <div className="row">
                {post_pair.map(post => blogPost(post)) }
            </div>
        </div>

        )
};

const blogPost = (post) => (
    <div className="col-lg-6 pt-3" key={post.link}>
        <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

        <div>
            <p>{post.date.toLocaleDateString()}</p>

            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-large text-blue-2 text-uppercase">
                {post.title}
            </h2>
        </div>

        <p className="d-block font-alt mb-3 text-medium">
            <a href={"/blogc?n=" + post.link} className="text-black-light text-underline">Read More</a>
        </p>

    </div>
)

export function Blog() {

    return (
        <div>
            <section id="blog" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-8 pt-3 text-center">

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Blog
                            </h2>

                        </div>

                    </div>

                </div>

            </section>


            <div className="container text-align-lleft">
                <div className="row">

                    {_.chunk(posts, 2).map((post_pair, idx) => (
                        blogRow(post_pair, idx)
                    ))}

                </div>
            </div>


            <div className="container text-align-right">
                <div className="row">
                    <div className="col-lg-12 pt-3">

                        <p className="d-block font-alt mt-5 mb-3 text-large">
                            <a href="/bloggg" className="text-blue-2 text-underline">Older Posts ></a>
                        </p>

                    </div>
                </div>
            </div>


            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>
        )

}