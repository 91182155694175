export function OnRamp() {

    return (
        <div>

            <section id="onramp" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                On-Ramp
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">
                        Commercial Service Automated Enrollment
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Prolific Banking provides open digital engagement platforms enabling financial
                        institutions to better serve business, commercial and corporate customers using
                        a modern and flexible onboarding solution.
                    </p>
                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Eliminate paper forms or PDF documents and automate the complete processing
                        workflow.
                    </p>
                </div>
            </div>

            <div className="row h-100 text-align-center">
                <div className="col-lg-3"/>
                <div className="col-lg-6 pt-3">
                    <img className="mt-3 mb-3" src="assets/images/onramp-phases.png" />
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        This SaaS offering is easy to acquire, implement and extend to:
                    </p>
                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Integrate and automate customer and service information collection</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Enroll products and services</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Automatically and securely  deliver customer data to Bank applications</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Provide management reporting</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">What the service includes</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Form Designer and Application Designer for capturing Organization details, service selections ,and service settings
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Enroll products and services</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Customer Engagement
                            <ul>
                                <li>information collection, agreement management, e-sign</li>
                                <li>secure customer access, secure file send/receive, online chat</li>
                            </ul>
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Service Enrollment (manual or automated)  to back-end   applications</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-center">
                <div className="col-lg-3"/>
                <div className="col-lg-6 pt-3">
                    <img className="mt-3 mb-3" src="assets/images/designer_tools.png" />
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <p className="font-alt mb-0 mt-3 font-weight-bold text-xs-large">
                        Add-on service components:
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Extending the On-Ramp service is easy and the following components can be added for additional fees:
                    </p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Automated Enrollment Application Interfaces (custom)</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Core integration (custom)</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">CRM integration (custom)</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">On-Ramp Benefits</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Centralized customer information:
                            <ul>
                                <li>Searchable and centralized repository for customer setup information</li>
                                <li>Multiple banking departments (if entitled) can access this data in a secure manner</li>
                            </ul>
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Service enrollment reporting:
                            <ul>
                                <li>Sales and operationa</li>
                                <li>Real-time reporting on customer usage across all business and commercial services</li>
                            </ul>
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Onboarding performance:
                            <ul>
                                <li>Per Service</li>
                                <li>Service Level Agreement (SLA) monitoring</li>
                                <li>Customer enrollment status reporting</li>
                            </ul>
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">SLA (Service Level Agreement) monitoring</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Alerts and notifications for each risk, audit, compliance, or other related tasks</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Summary and detail reporting</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">On-Ramp also supports</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Requests for  information or agreements for customer signature
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Multi-department workflow for task review and approval
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Integrated secure messaging for internal communication and external client correspondence
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Integration with core systems, crm, online banking platforms and other digital surrounds
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>

        )

}