
export function OnRecord() {

    return (
        <div>

            <section id="onrecord" className="bg-cover bg-gray bg-overlay-black-8 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                On-Record
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">
                        Commercial Compliance Automation
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Compliance tasks are nothing new to the cash management world, but as banks begin to introduce new and
                        exciting payment offerings the burden it places on both internal teams as well as their clients continues to
                        grow.  We aim to provide our partners with a tool designed specifically to meet their unique needs in responding
                        to and executing on their compliance and risk requirements.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        <span className="font-weight-bold">On-Record</span>, your answer to compliance automation delivered through the
                        <span className="font-weight-bold font-italic">Prolific Open Digital</span> platform focused on data delivery, exchange, and integration.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        On-Record facilitates continuous engagement with business customers for regulatory,
                        security and audit tasks through automated workflows that leverage the platform’s internal
                        features and banking application integrations.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        With On-Record, the bank can use any data point in On-Ramp to schedule a task interval and
                        related workflow such as annual remote deposit or ACH limit review, quarterly company contact
                        checks, or annual customer agreement renewals. On-Record tasks can be scheduled, recurring, or on-demand.
                    </p>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">On-Record use cases</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            ACH, Wire, and Remote Deposit risk and limit reviews
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Credit/Exposure Approvals
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Risk and Exception Approvals
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Customer agreement renewals
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Internal compliance tasks
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Internal approvals from account officer, relationship manager, risk department, and other roles
                        </li>
                    </ul>
                </div>
            </div>


            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">

                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">On-Record also supports</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Requests for information or agreements for customer signature</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Multi-department workflow for task review and approval</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Integrated secure messaging for internal communication and external client correspondence</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">

                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">On-Record provides</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Audit record of each interaction</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">SLA (Service Level Agreement) monitoring</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Alerts and notifications for each risk, audit, compliance, or other related tasks</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Summary and detail reporting</li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>

        )

}