

export function TreasuryOnboarding() {
    return (
        <section className="bg-gray-light pt-5 pb-5">
            <div className="container border-bottom">
                <div className="row mb-5">
                    <div id="serviceonboarding" className="anchor"/>
                    <div className="d-flex flex-column justify-content-start ">
                        <div>
                            <span className="bg-base-color d-inline-block sep-line"></span>
                            <span
                                className="text-align-left d-block font-alt letter-spacing-2 text-uppercase title-medium">Treasury Service Onboarding</span>

                        </div>
                        <div className={"mt-1 text-blue"}>Tired of how long and complicated it is to for a
                            commercial customer to start doing business with you?
                        </div>

                        <div className="mt-2">
                            <p className="text-left  text-medium">
                                The new way to Onboard businesses to your bank:
                                <ul>
                                    <li>Digitized & Automated</li>
                                    <li>Speed without Mistakes</li>
                                    <li>Trackable</li>
                                    <li>Customer Centric</li>
                                </ul>
                            </p>
                            <p className="mt-2 text-medium">
                                An end-to-end onboarding solution that includes service provisioning integrated with
                                core systems, CRM, online banking platforms, and other digital surrounds. Enables
                                customer self-service with secured compliant onboarding and easily facilitates the
                                migration of business customers from one digital platform to another.
                            </p>

                        </div>

                        <p className="text-left text-blue">
                            The On-Ramp Treasury Service Onboarding module supports:
                        </p>

                        <p className="text-left  text-medium">
                            <ul>
                                <li>A Digital Services Enrollment Form

                                    <ul>
                                        <li>Customizable by the financial institution to collect customer
                                            information, eliminating the need for paper forms
                                        </li>
                                        <li>Accessible by business customers to establish the services they need
                                            to do business
                                        </li>
                                    </ul>

                                </li>

                                <li>Customer agreements, documentation exchange, e-signing and online chat</li>

                                <li>Workflow Designer for creating process flows and associated tasks</li>

                                <li>The automated and secure deliver of customer data to financial institution
                                    applications
                                </li>

                                <li>Service enrollment for customer availability</li>

                                <li>Management reporting for each step of the process</li>

                                <li>Automation and support for client product migrations, along with real-time
                                    tracking of customer login and usage
                                </li>
                            </ul>
                        </p>

                    </div>

                </div>
            </div>
        </section>
                );
                }
