import {Link} from "react-router-dom";
import {AccountOpening} from "./products/AccountOpening";
import {FiStreams} from "./FiStreams";
import {TreasuryOnboarding} from "./products/TreasuryOnboarding";
import {FinancialReporting} from "./products/FinancialReporting";
import {PaymentTransactions} from "./products/PaymentTransactions";

export function Products() {
    return(
        <section id="products" className="bg-gray-light mb-lg-n5 border-bottom">

            <div id="products-bg" className="bg-cover bg-base-color bg-overlay-black-2 mt-n5 border-top">

                <div className="container">

                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-8 pt-5 text-center pb-5">

                            <span className="bg-base-color d-inline-block mt-4 sep-line-medium-thick"></span>

                            <h2 className="font-alt font-base letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Products
                            </h2>

                        </div>

                    </div>

                    {/*<div className="row justify-content-center border-bottom">*/}
                    {/*    <div className="col-lg-12 text-white">*/}
                    {/*        <h3 className="font-alt font-w-600 letter-spacing-2 title-xs-small title-extra-large-2">Products</h3>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className="row justify-content-center pb-2 bg-cover bg-base-color bg-overlay-black-2 mt-n5">*/}

                    {/*    <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Products</h3>*/}

                    {/*</div>*/}

                    {/*<div className="row justify-content-center pb-2">*/}
                    {/*    <div className="col-lg-9 pb-lg-4 text-center">*/}
                    {/*        <span className="bg-base-color d-inline-block sep-line-thick-long"></span>*/}
                    {/*        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Products</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>

            </div>

            <AccountOpening/>

            <TreasuryOnboarding/>

            <FinancialReporting/>

            <PaymentTransactions/>

        </section>
    )
}
