import { InlineWidget } from "react-calendly";

export function Contact() {
    return(
        <section id="contact" className="bg-cover bg-gray bg-overlay-black-6 mt-lg-5">
            <div className="container mt-lg-n5">

                <div className="row align-items-center h-100 justify-content-center">
                    <div className="col-lg-8 pt-3 text-center">

                        <span className="bg-base-color d-inline-block mt-4 sep-line-medium-thick"></span>

                        <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                            Contact us
                        </h2>

                    </div>

                </div>

                <div className="row justify-content-center mt-5">

                    {/*<div className="col-lg-6 mt-5 mt-lg-0">*/}
                    {/*    /!*<InlineWidget url="https://calendly.com/joe_spatarella_prolific" />*!/*/}

                    {/*</div>*/}


                    {/*<div className="col-lg-6 text-white">*/}
                    {/*    <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Get*/}
                    {/*        In Touch</h3>*/}
                    {/*    <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">We can't wait to hear from you</p>*/}
                    {/*    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"></span>*/}

                    {/*    <div className="mt-4 text-extra-large text-white">*/}
                    {/*        <p>Complete the form for more information <br/> or to schedule a meeting.</p>*/}
                    {/*        <span className="d-block">E: info@prolificbanking.com</span>*/}
                    {/*        <span className="d-block mt-1">P: 1-833-PROLIFC (776-5432)</span>*/}
                    {/*    </div>*/}

                    {/*</div>*/}


                    <div className="col-lg-6 mt-5 mt-lg-0">

                        <p className="font-alt mb-0 mt-5 text-xs-large text-uppercase text-white title-medium">We can't wait to hear from you</p>

                        <p className="font-alt mb-0 mt-5 text-xs-large text-uppercase text-white title-medium">1-833-PROLIFC (776-5432)</p>

                        <p className="font-alt mb-0 mt-5 text-xs-large text-uppercase title-medium">
                            <a href="mailto:info@prolificbanking.com" className="text-white ">info@prolificbanking.com</a>

                        </p>



                    </div>

                </div>

                <div className="row justify-content-center">

                    {/*<div className="col-lg-6 mt-5 mt-lg-0">*/}
                    {/*    <InlineWidget url="https://calendly.com/joe_spatarella_prolific" />*/}

                    {/*</div>*/}

                </div>


            </div>

        </section>
    )
}