
export function About() {
    return(
        <section id="about" className="bg-white">
            <div className="container">
                <div className="row justify-content-center pb-5">
                    <div className="col-lg-9 pb-lg-4 text-center">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Prolific
                            Banking</h3>
                        {/*<p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Unleash the power of*/}
                        {/*    Prolific Banking</p>*/}

                        <p className="font-alt text-extra-large text-left mt-4">
                            Empowering financial institutions with a digital engagement platform, simplifying and
                            automating commercial onboarding and product delivery capabilities, Atlanta-based
                            Prolific Banking was founded in 2020 by a team experienced in commercial banking and
                            software development.
                        </p>

                        <span className="bg-base-color d-inline-block sep-line-thick-long"></span>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-6">
                        <div className="carousel-custom" data-pagedots="true" data-draggable="false"
                             data-autoplay="5000">
                            {/*<div className="carousel-cell w-100">*/}
                            {/*    <img src="assets/images/on-ramp-fpc.jpg" alt=""*/}
                            {/*         className="img-fluid box-shadow-shallow rounded"/>*/}
                            {/*</div>*/}

                            <div className="carousel-cell w-100">
                                <img src="assets/images/on-ramp-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>


                            {/*<div className="carousel-cell w-100">*/}
                            {/*    <img src="assets/images/on-record-fpc.jpg" alt=""*/}
                            {/*         className="img-fluid box-shadow-shallow rounded"/>*/}
                            {/*</div>*/}

                            <div className="carousel-cell w-100">
                                <img src="assets/images/on-record-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>


                            <div className="carousel-cell w-100">
                                <img src="assets/images/fi-streams-g-fpc.png" alt=""
                                     className="img-fluid rounded"/>
                            </div>

                        </div>

                    </div>


                    <div className="col-lg-6">
                        <div className="pl-lg-4 pt-1 pt-lg-0">

                            <p className="font-alt mb-0 text-xs-large text-uppercase title-medium mb-3">Our Mission</p>

                            <p className="font-alt text-extra-large text-left">
                                The <span className="prolific-blue"> Prolific Banking </span> mission is to facilitate
                                the digital transformation of business and commercial banking. We enable financial
                                institutions to compete in a modern technology environment with a level of digital
                                engagement that improves client experience, provides efficiencies and scale, expands
                                customer relationships, and generates new revenue streams.
                            </p>

                            <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium mb-3 mt-4">Platform</p>

                            <p className="font-alt text-extra-large text-left">
                                <span className="prolific-blue"> Prolific Open Digital </span> is a cloud-based transformational commercial banking platform combining internal bank and customer-facing applications. Using a digital-first engagement model, financial institutions can benefit from automated account opening, treasury service onboarding, account information and payment transaction data delivery, fintech connectivity and multiple other services, focusing on those that meet their current needs and future goals.
                            </p>

                            <a href="#products" className="page-scroll btn btn-base-color btn-shadow mt-4">Read
                                More</a>
                        </div>

                    </div>

                </div>

            </div>

        </section>
    )
}
