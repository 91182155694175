import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
export function BlogContent() {

    const [content, setContent] = useState("");

    let query = useQuery();

    useEffect(() => {
        fetch('/blog/' + query.get("n") + '.html')
            .then(response => response.text())
            .then(text => setContent(text))
    }, []);


    return (
        <div>

            <section id="blog" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-9 pt-3 text-center">

                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Blog
                            </h2>

                        </div>

                    </div>

                </div>

            </section>

                <div className="container text-align-lleft">
                    <div className="row">
                        <div className="col-lg-1 pt-3"/>
                        <div className="col-lg-9 pt-3">
                            <span className="bg-base-color d-inline-block sep-line-medium-thick"/>

                            <div dangerouslySetInnerHTML={{__html: content}} />

                        </div>
                        <div className="col-lg-1 pt-3"/>

                    </div>

                </div>

            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>


        </div>
        )

}