
export function WhyUs() {
    return(
        <section id="why-us" className="bg-gray-light mt-lg-n5">
            <div className="container">
                <div className="row justify-content-center pb-5">
                    <div className="col-lg-9 pb-lg-4 text-center">
                        <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">What sets us apart</h3>
                        <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">Purpose Built for Financial Institutions</p>
                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"></span>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-4">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-bank bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Purpose Built</span>
                                <p className="mt-1 text-large">A vertical commercial digital platform for financial institutions.</p>
                            </div>

                        </div>

                    </div>


                    <div className="col-md-6 col-lg-4 mt-5 mt-md-0">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-code bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Integrations</span>
                                <p className="mt-1 text-large">Integrations with Core, CRM, Digital Banking, Payment and Commercial
                                    Applications ( ACH, Wire, Remote Deposit, BillPay, Lockbox ) and more.</p>
                            </div>

                        </div>

                    </div>


                    <div className="col-md-6 col-lg-4 mt-5 mt-lg-0">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-check-square-o bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Commercial Entitlements</span>
                                <p className="mt-1 text-large">Business and Commercial entitlement structures with
                                    service specific limits, approvals and audit.</p>
                            </div>

                        </div>

                    </div>


                    <div className="col-md-6 col-lg-4 mt-5">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-expand bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Flexible</span>
                                <p className="mt-1 text-large">Role based solution enabling multiple banking departments
                                    to conditionally action within an engagement workflow.</p>
                            </div>

                        </div>

                    </div>


                    <div className="col-md-6 col-lg-4 mt-5">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-lock bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Security</span>
                                <p className="mt-1 text-large">Integrated security, digital signatures, compliance
                                    reporting, service level monitoring.</p>
                            </div>

                        </div>

                    </div>


                    <div className="col-md-6 col-lg-4 mt-5">
                        <div className="pl-5 position-relative">
                            <i className="fa fa-circle-o-notch bg-base-color left-0 mt-1 p-3 position-absolute top-0 rounded text-white"></i>
                            <div className="ml-3">
                                <span
                                    className="d-block font-alt letter-spacing-2 title-medium text-uppercase">Holistic</span>
                                <p className="mt-1 text-large">End-to-end digital automated processes for the entire customer engagement lifecycle.</p>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </section>
    )
}
