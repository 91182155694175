import {FirstSection} from "./FirstSection";
import {About} from "./About";
import {Products} from "./Products";
import {Team} from "./Team";
import {Careers} from "./Careers";
import {WhyUs} from "./WhyUs";
import {Contact} from "./Contact";
import {Footer} from "./Footer";
import {Link} from "react-router-dom";

export function ProductDetails() {

    return (
        <div>

            <section id="onramp" className="bg-gray-light">
                <div className="container">


                    {/* On-Ramp - On-Record - Fi-Streams */}
                    <div className="row">

                        <div className="col-md-12">
                           On-Ramp

                            <p>
                            Prolific Banking provides Banks with Enrollment-as-a-Service and optionalAutomated Enrollment and Secure Customer Access for  end-to-e nd   digital business and commercial service enrollment and   onboarding.   Eliminate paper forms or PDF   documents and automate the complete processing workflow.
                            </p>

                            <p>
                                This SaaS offering is easy to acquire, implement and extend to: •Integrate and automate customer and service information collection•Enroll products and services•Automatically and   securely  deliver customer data to Bank applications•Provide management reporting
                            </p>

                            <p>
                                What the   service includes: •Form Designer and Application Designer for capturing Organization details,service selections,and  service settings•Customer Engagementoinformation collection, agreement management, e-signosecure customer access, secure file send/receive, online chat•Service Enrollment (manual or automated)  to back-end   applications)
                            </p>

                            <p>
                                Add-on service components: Extending the On-Ramp service is easy and the following components can be added for additional fees: •Automated Enrollment Application Interfaces (custom)•Core integration (custom)•CRM integration (custom)On-Ramp Benefits: •Centralized customer information:oSearchable and centralized repository for customer setup informationoMultiple banking departments (if entitled) can    access this data in a   secure manner•Service enrollment reporting:oSales and  operationaloReal-t ime reporting on customer usage across all   business and commercial services•Onboarding performance:oPer    serviceoService Level Agreement (SLA) monitoringoCustomer enrollment status reporting•SLA (Service Level Agreement) monitoring•Alerts and   notifications    for   each risk, audit,    compliance, or other related   tasks•Summary and detail  reportingOn-Ramp   also supports: •Requests for  information or agreements for customer signature•Multi-department workflow for task review and approval•Integrated secure messaging for internal communication and external client correspondence•Integration with core systems, crm, online banking platforms and other digital surrounds
                            </p>

                            <p>

                            </p>

                        </div>

                    </div>

                </div>

            </section>

            <section id="onrecord" className="bg-gray-light">
                <div className="container">


                    {/* On-Ramp - On-Record - Fi-Streams */}
                    <div className="row">

                        <div className="col-md-12">
                            On-Record
                        </div>

                    </div>

                </div>

            </section>

            <section id="fistreams" className="bg-gray-light">
                <div className="container">


                    {/* On-Ramp - On-Record - Fi-Streams */}
                    <div className="row">

                        <div className="col-md-12">
                            Fi-Streams
                        </div>

                    </div>

                </div>

            </section>

        </div>
        )

}