import {FirstSection} from "./FirstSection";
import {About} from "./About";
import {Products} from "./Products";
import {Team} from "./Team";
import {Careers} from "./Careers";
import {WhyUs} from "./WhyUs";
import {Contact} from "./Contact";
import {Footer} from "./Footer";
import {Platform} from "./Platform";

export function Main() {

    return (
        <div>

            <FirstSection/>

            {/*<SecondSection/>*/}

            <About/>

            <Platform />

            <Products />

            <WhyUs />

            <Team />

            <Careers />

            <div className="mt-5 mb-5">
                <span>&nbsp;<br/></span>
            </div>

            <Contact />


        </div>
        )

}
