

export function AccountOpening() {
    return (
        <section className="bg-gray-light pt-5 pb-5">
            <div className="container border-bottom">
                <div className="row mb-3">
                    <div id="accountopening" className="anchor"/>
                    <div className="d-flex flex-column justify-content-start ">
                        <div>
                            <span className="bg-base-color d-inline-block sep-line"></span>
                            <span
                                className="text-align-left d-block font-alt letter-spacing-2 text-uppercase title-medium">Account Opening</span>

                        </div>
                        <div className={"mt-1 text-blue"}>Faster Account Opening your Customers can initiate</div>

                        <div className="mt-2">
                            <p className="text-left  text-medium">
                                A self-service tool that allows business customers to initiate the account opening
                                process and manages the exchange of agreements, KYC and KYB, making it easier and
                                quicker for them to start doing business with you.
                            </p>
                        </div>

                        <p className="text-left text-blue">
                            The On-Ramp Account Opening module supports:
                        </p>

                        <p className="text-left  text-medium">
                            <ul>
                                <li>A Digital Account Opening Application

                                    <ul>
                                        <li>Customizable by the financial institution to ensure it includes
                                            fields for information they want to capture
                                        </li>
                                        <li>Accessible by new business customers to establish initial accounts
                                            and/or for existing customers to add accounts to their relationship
                                        </li>
                                    </ul>

                                </li>

                                <li>Account opening on financial institution core for account number assignment
                                    (manual or automated)
                                </li>

                                <li>API-based and bulk account opening for businesses that need to open large
                                    number of accounts
                                </li>

                                <li>Customer agreements, documentation exchange, e-signing and online chat</li>

                                <li>KYC verification with third-party data / analytics companies</li>

                                <li>Optional KYB (Know Your Business) business identity report</li>

                                <li>Optional OCR support for ID verification</li>

                                <li>Account Funding Options (via ACH and wire transfer)</li>

                                <li>Workflow Designer for creating process flows and associated tasks</li>

                                <li>Management and Audit Reporting for all activities initiated by customers or
                                    financial institution staff
                                </li>

                            </ul>
                        </p>

                    </div>

                </div>
            </div>
        </section>);

}
