import {Link} from "react-router-dom";

export function Platform() {
    return(
        <section id="platform" className="bg-gray-light mb-lg-n5">

            <div className="container">
                    <div className="row justify-content-center pb-3">
                        <div className="col-lg-10 pb-lg-4 text-center">
                            <h3 className="font-alt font-w-600 letter-spacing-2 text-uppercase title-xs-small title-extra-large-2">Prolific
                                Open Digital Platform</h3>
                            <p className="font-alt mb-0 mt-3 text-xs-large text-uppercase title-medium">The foundational
                                platform for digital transformation</p>

                            <p className="font-alt mb-0 mt-3 text-xs-large">Engage business and commercial customers
                                throughout their digital engagement journey.</p>

                            <img src="assets/images/pod2.png" width="750" alt="" className="logo-text-light mt-2"/>

                            <p className="font-alt mb-0 mt-3 text-xs-large">
                                Prolific Banking developed the Prolific Open Digital platform to support bank digital
                                engagement
                                with business and commercial customers. Bringing a unique and unprecedented approach to
                                commercial
                                onboarding, automated service provisioning and compliance, delivered through various
                                platform modules
                            </p>

                            <div className="text-center mt-4">
                                <Link className="page-scroll btn btn-base-color btn-shadow mt-1" to="/pod"
                                      target="page-top">Read More</Link>
                            </div>

                        </div>
                    </div>

                </div>

        </section>
)
}
