
export function FirstSection() {
    return(
        <section id="home" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100">
            <div className="container h-100">
                <div className="row align-items-center h-100 justify-content-center">
                    <div className="col-lg-8 pt-3 text-center">
                        <span className="d-block font-alt font-w-600 letter-spacing-2 text-medium text-uppercase text-white">
                            Digitally transforming Commercial and Business Banking
                        </span>

                        <span className="bg-base-color d-inline-block mt-4 sep-line-medium-thick"></span>

                        <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                            Digital Client Engagement for Business
                        </h2>

                        <span className="d-block font-alt letter-spacing-2 mb-3 mt-4 text-large text-uppercase text-white">
                            Prolific Banking is a Technology Enablement Partner For Financial Institutions
                        </span>

                        <a href="#about" className="page-scroll btn btn-base-color btn-large btn-shadow mr-0 mt-4">Explore Now</a>
                    </div>

                </div>

            </div>

        </section>
    )
}
