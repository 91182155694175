import {Link} from "react-router-dom";

export function Pod() {

    return (
        <div>

            <section id="pod" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Prolific Open Digital Platform
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">
                        Commercial Digital Banking Platform
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Prolific Open Digital is a purpose-built digital banking platform designed by commercial banking industry
                        veterans and software development professionals committed to providing open and innovative commercial solutions
                        for financial institutions and their customers.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">All <span className="font-weight-bold"> Prolific Open Digital </span>modules leverage many of the platform features, including but not limited to:</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Business and commercial banking entitlements with service specific limits, approvals, and audit.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Role based solution secured with Multi-Factor Authentication and Open Authentication Support. </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Dynamic and flexible “no-code” platform tools enable evolving engagement models as your bank grows.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Cloud native platform enables elasticity, performance at scale, modern security, and high availability.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Foundational internal and external APIs provide embedded banking capabilities and support bank technology team direct integrations.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Integrations with Core, CRM, Digital Banking, Payments, and other Third-Party Provider applications.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Fundamental engagement with a modern UI, secure real-time messaging, and digital signatures enables bank customers to respond to and engage with the bank from any device [mobile,tablet, desktop] without having to download sensitive data to a local insecure storage device.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">SLA Monitoring and Detailed Status Reporting.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Subscription based notifications enable organizations to stay up to date and receive reminders on each of their assigned risk, audit, compliance, or other tasks.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Track, monitor and manage data and transactions with a dashboard overview and detailed drill down capability which provides end-to-end tracing for all platform activities.</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Securely connect to customer or partner systems or delivery endpoints with a simplified UI.</li>
                    </ul>

                </div>
            </div>

        </div>

        )

}
