

export function FinancialReporting() {
    return (
        <section className="bg-gray-light pt-5 pb-5">
            <div className="container border-bottom">
                <div className="row mb-5">
                    <div id="financialreporting" className="anchor"/>
                    <div className="d-flex flex-column justify-content-start ">
                        <div>
                            <span className="bg-base-color d-inline-block sep-line"></span>
                            <span
                                className="text-align-left d-block font-alt letter-spacing-2 text-uppercase title-medium">Financial Reporting</span>

                        </div>
                        <div className={"mt-1 text-blue"}>Stop struggling to figure out how to deliver account data to
                            your
                            customers, we can make the process really easy.... And fast!
                        </div>

                        <div className="mt-2">
                            <p className="text-left  text-medium">
                                No matter how your customers want to receive their data, or in what format, our
                                FI-Streams
                                Reporting product will handle it. No need to wait for technical experts to work with the
                                customer, no need to make them wait and to delay your revenue generation
                            </p>
                        </div>

                        <p className="text-left text-blue">
                            FI-Stream Reporting provides Data Streaming capability with end-to-end data delivery
                            exchanged
                            through a variety of digital channels
                        </p>

                        <div className="d-flex justify-content-center ">
                            <img src="assets/images/information_reporting.png" width="850" alt=""
                                 className="logo-text-light mt-2 border-blue"/>
                        </div>

                    </div>

                </div>
            </div>
        </section>
                );
                }
