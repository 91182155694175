import {FirstSection} from "./FirstSection";
import {About} from "./About";
import {Products} from "./Products";
import {Team} from "./Team";
import {Careers} from "./Careers";
import {WhyUs} from "./WhyUs";
import {Contact} from "./Contact";
import {Footer} from "./Footer";
import {Link} from "react-router-dom";

export function FiStreams() {

    return (
        <div>

            <section id="fistreams" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-100">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                Fi-Streams
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">
                        Digital Data Exchange For Commercial Open Banking
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        There are several environmental shifts rapidly changing the digital banking landscape:
                    </p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Businesses are moving faster, are highly responsive to market changes</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Global demand on logistics and supply chains is increasing at an unprecedented rate</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Commercial payments are moving to real-time and instant, requiring real-time information tom anage risk, process payables and apply receivables</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">High demand for financial data and the ability to integrate that data with cloud-based financial applications and fintech solutions</li>
                    </ul>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        As a result, what used to be called “open banking” is now evolving to embedded banking, contextual banking, and embedded finance, suggesting a significant change from traditional web viewing and reporting to real-time data delivery and integration.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Fi-Streams service gives Banks data streaming capability with end-to-end digital data delivery and exchange through a variety of digital channels.
                    </p>

                    <p className="font-alt mb-0 mt-3 text-xs-large">
                        Prolific Open Digital Fi-Streams, scheduled for commercial availability in 2023, is a cloud-based solution that enables banks to provide financial data to businesses, commercial customers, and FinTech partners when, and how they need it.
                    </p>

                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">Fi-Streams Data Types</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Standard Banking Message Formats: BAI2, NACHA, ISO2022
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Generic Message Formats: TEXT, XML, CSV, JSON</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Accounts Receivables / Accounts Payable</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">ACH: EDI, Returns, NOC</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Price / billing data (EDI 822)</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">E-Statements and Notices</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">Fi-Streams Channel Exchange Options</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            API based (inbound or outbound) to enable bank customers and FinTech partners seamless integration for large corporate workflows and/or extending financial reporting capabilities
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Cloud Storage Integration for secure and direct transfer to business and corporate environments without requiring traditional file transmission configuration
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Web and tablet-based exchanges for direct viewing</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Traditional file transfer methods such as SFTP</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Stream status notification with E-mail, SMS and/or push notifications</li>
                    </ul>
                </div>
            </div>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-2"/>
                <div className="col-lg-8 pt-3">
                    <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>

                    <p className="font-alt mb-0 mt-2 text-xs-large text-uppercase title-medium">Fi-Streams Aggregation and Transformation</p>

                    <ul>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Transform between standard and non-standard message formats
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">
                            Zero-code adjustments of standard formats for customer specific requests
                        </li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Multi-organizational contexts for data aggregation and consolidated exchange of financial data</li>
                        <li className="font-alt mb-0 mt-3 text-xs-large">Customer level multi-bank aggregation capabilities for streaming multi-relational financial data</li>
                    </ul>
                </div>
            </div>

            <div className="container mt-5">
                <div className="row align-items-center border-top"/>
            </div>

        </div>

        )

}