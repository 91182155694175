
export function Careers() {
    return(
        <div>
        <section id="careers" className="bg-cover bg-gray bg-overlay-black-6">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 text-white">
                        <h3 className="font-alt font-w-600 letter-spacing-2 title-xs-small title-extra-large-2">Do you have the talent, experience, and drive to be Prolific?</h3>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 text-white text-align-lleft">
                        <p className="font-alt mb-0 mt-5 text-xs-large text-uppercase title-medium">What we do</p>

                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>
                        <div className="mt-1 text-extra-large text-white">
                            <p>
                                Prolific Banking provides open digital engagement platforms enabling financial institutions to better serve business, commercial and corporate customers using a modern and flexible onboarding solution.
                            </p>
                        </div>

                        <p className="font-alt mb-0 mt-5 text-xs-large text-uppercase title-medium">Why Prolific Banking?</p>

                        <span className="bg-base-color d-inline-block mt-4 sep-line-thick-long"/>
                        <div className="mt-1 text-extra-large text-white">
                            <p>
                                Experienced management team – decades of experience in the banking industry separates us from the pack.  We understand the business, the technology and have a track record of success.
                                New Development and Innovation – new technologies, new products and new solutions.
                                Privately funded, Prolific Banking is a private company focused on our core values:
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-12 text-white mt-5">
                            <p>
                                Act with integrity  |  Support with a passion  |  Focus on customers  |  Delivery quality
                            </p>
                    </div>

                </div>

            </div>

        </section>

        <div className="bg-cover bg-base-color bg-overlay-black-2 mt-n5">
            <div className="container">

                <div className="row">
                    <div className="col-lg-3 text-white mt-5">
                        <p className="font-alt font-w-600 mb-0 mt-1 text-xs-large text-uppercase title-medium">Your move.</p>
                    </div>

                    <div className="col-lg-3">

                    </div>

                    <div className="col-lg-6 text-white text-align-lleft mt-3 mb-3">
                        <div className="mt-1 text-extra-large text-white">
                            <p>
                                If you are interested in a career opportunity with us, send your resume to <a className="text-white text-underline" href="mailto:careers@prolificbanking.com">careers@prolificbanking.com</a>.
                                We will be in touch if a role is available that matches your skills and experience.
                            </p>
                            <p>
                                If we determine you are a fit for the position, we’ll send you a job description and benefits summary.
                            </p>
                        </div>

                    </div>

                </div>


            </div>

        </div>

        </div>
    )
}
