import React from "react";
import "rapidoc";

export function ApiContent() {
    return (
        <div>
            <section id="apidocs" className="bg-cover bg-gray bg-overlay-black-6 h-100 w-100 mt-auto">
                <div className="container h-75">
                    <div className="row align-items-center h-100 justify-content-center">
                        <div className="col-lg-10 pt-3 text-center">
                            <h2 className="font-alt font-w-400 letter-spacing-2 mb-3 mt-3 text-uppercase text-white title-xs-extra-large title-extra-large-2">
                                API Documentation
                            </h2>
                        </div>
                    </div>
                </div>
            </section>

            <div className="row h-100 text-align-lleft">
                <div className="col-lg-12">
                    <rapi-doc
                        style={{ height: "100vh", width: "100%" }}
                        spec-url="http://system-localhost:9090/api/swagger.json"
                        render-style="focused"
                    />
                </div>
            </div>


        </div>

        )

}